import Vue from 'vue'
import Router from 'vue-router'
import VueScrollTo from 'vue-scrollto'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'home-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/HomePage.vue')
  }]
}, {
  path: '/home',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'home-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/HomePage.vue')
  }]
},{
  path: '/about',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'about-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/AboutPage.vue')
  }]
}, {
  path: '/events',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'events-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/EventsPage.vue')
  }]
},
{
  path: '/watch',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'watch-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/WatchPage.vue')
  }]
}, {
  path: '/getconnected',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'connected-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ConnectedPage.vue')
  }]
},  {
  path: '/prayer',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'prayer-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PrayerPage.vue')
  }]
},  {
  path: '/decisionforchrist',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'decision-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/DecisionPage.vue')
  }]
},  {
  path: '/contact',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'contact-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ContactPage.vue')
  }]
},  {
  path: '/youth',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'youth-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/YouthPage.vue')
  }]
},  {
  path: '/childrenscorner',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'childrens-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ChildrensPage.vue')
  }]
},  {
  path: '/adults',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'adults-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/AdultsPage.vue')
  }]
},  {
  path: '/sports',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'sports-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SportsPage.vue')
  }]
},  {
  path: '/missions',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'missions-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/MissionsPage.vue')
  }]
},  {
  path: '/ministries',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'ministries-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/MinistriesPage.vue')
  }]
},  {
  path: '/resources',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'resources-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ResourcesPage.vue')
  }]
},
{
  path: '/forms',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'forms-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/FormsPage.vue')
  }]
},  {
  path: '/terms',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'terms',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/TermsPage.vue')
  }]
}, {
  path: '/privacy',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PrivacyPage.vue')
  }]
},{
  path: '/admin',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/SimpleLayout'),
  children: [{
    path: 'banners',
    name: 'banners-page',
    component: () => import(/* webpackChunkName: "banners-page" */ '@/pages/admin/BannersPage'),
    meta: {
      title: 'Banners'
    }
  },{
    path: 'bulletin',
    name: 'bulletin-page',
    component: () => import(/* webpackChunkName: "bulletin-page" */ '@/pages/admin/BulletinPage'),
    meta: {
      title: 'Bulletin'
    }
  },{
    path: '/',     
    component: () => import(/* webpackChunkName: "bulletin-page" */ '@/pages/admin/AdminPage')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0
  
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash, 700)
      
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }
  
    return { x: 0, y: scrollTo }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
