import configs from '../../configs'
import mutations from './mutations'

const { product, theme  } = configs
const { isRTL, globalTheme } = theme

// state initial values
const state = {
  product,

  // app theme dark or light
  globalTheme,

  // themes and layout configurations
  isRTL,

  userDetails: null
}

// getters
const getters = {
  isAuthenticated: (state, getters, rootState) => {
    return state.userDetails !== undefined && state.userDetails !== null
  },

  canUploadBanners: (state, getters, rootState) => {
    return state.userDetails !== undefined && state.userDetails !== null && state.userDetails.canUploadBanners
  },

  canUploadBulletin: (state, getters, rootState) => {
    return state.userDetails !== undefined && state.userDetails !== null && state.userDetails.canUploadBulletin
  }
}

// actions
const actions = {
  setUserDetails ({ commit, state }, userDetails) {
    commit('storeUserDetails', userDetails)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
